import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import ImageFb from "../images/fb-cover.jpg"

function SEO({ title, description, image }) {
  const { pathname } = useLocation()
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    imagefb: `${siteUrl}${ImageFb || defaultImage}`,
  }

  return (
    <Helmet title={seo.title} defer={false}>
      {/* Primary Meta Tags */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.imagefb} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.imagefb && <meta property="og:image" content={seo.imagefb} />}

      {/* Twitter  */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.imagefb && <meta name="twitter:image" content={seo.imagefb} />}

      <meta name="robots" content="index, follow" />
      <meta name="Googlebot" content="follow" />
      <link rel="canonical" href={seo.url} />
      <link
        href="https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <meta name="gatsby-starter" content="Gatsby Starter Prismic" />
    </Helmet>
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SEO

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
          }
        }
      }
    `
  )
  return site.siteMetadata
}
